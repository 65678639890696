import { Routes } from '@angular/router';

export const APP_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () => import('./root-page/root-page.component').then(mod => mod.RootPageComponent)
  },
  {
    path: 'org/learnswell',
    loadChildren: () => import('./org/learnswell/internal.routes').then(mod => mod.INTERNAL_ROUTES)
  },
  {
    path: 'org/nbcrna',
    loadChildren: () => import('./org/nbcrna/nbcrna.routes').then(mod => mod.NBCRNA_ROUTES)
  },
  {
    path: 'org/silkoak',
    loadChildren: () => import('./org/silkoak/silkoak.routes').then(mod => mod.SILKOAK_ROUTES)
  },
  {
    path: 'org/silkoak-bn',
    loadChildren: () => import('./org/silkoak/silkoak-bn.routes').then(mod => mod.SILKOAK_BN_ROUTES)
  },
  {
    path: 'org/abfas',
    loadChildren: () => import('./org/abfas/abfas.routes').then(mod => mod.ABFAS_ROUTES)
  },
  {
    path: 'org/iars-eeg',
    loadChildren: () => import('./org/iars-eeg/iars-eeg.routes').then(mod => mod.IARS_EEG_ROUTES)
  },
  {
    path: 'org/abpm',
    loadChildren: () => import('./org/abpm/abpm.routes').then(mod => mod.ABPM_ROUTES)
  },
  {
    path: 'org/neomo',
    loadChildren: () => import('./org/neomo/neomo.routes').then(mod => mod.NEOMO_ROUTES)
  },
  {
    path: 'providers/:org',
    redirectTo: 'org/:org'
  },
  { path: '**', loadComponent: () => import('./not-found/not-found.component').then(mod => mod.NotFoundComponent) },
];
