<ng-container *ngIf="shouldShow">
  <div id="sky"></div>

  <div class="container vh-100 d-flex justify-content-center align-items-center zed-20">

    <div class="alert alert-danger">
      <h4>Application Error </h4>

      @if (promptQuickReload) {
        <p>Please check your internet connection, and try again in a few minutes.</p>
      } @else {
        <p>Loading failed, please try again.</p>
      }

      <div>
        <portal-contact-support></portal-contact-support>
      </div>
      <button type="button" (click)="doReload()" class="btn btn-primary mt-2">Click to try again</button>
    </div>

  </div>



  <div id="ground">
    <div class="ground-fade"></div>
    <svg class="circle-01" width="1280" height="341" viewBox="0 0 1280 341" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse opacity="0.3" cx="-75.5" cy="1343.5" rx="1451.5" ry="1343.5" fill="url(#paint0_radial_836_5988)"/>
      <defs>
        <radialGradient id="paint0_radial_836_5988" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-75.5 1343.5) rotate(90) scale(1343.5 1451.5)">
          <stop stop-color="#57AE8E"/>
          <stop offset="1" stop-color="#57AE8E" stop-opacity="0.66"/>
        </radialGradient>
      </defs>
    </svg>

    <svg class="circle-04" width="685" height="685" viewBox="0 0 685 685" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.75" cx="342.5" cy="342.5" r="342.5" fill="url(#paint0_radial_835_5965)"/>
      <defs>
        <radialGradient id="paint0_radial_835_5965" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(342.5 342.5) rotate(90) scale(342.5)">
          <stop stop-color="#57AE8E" stop-opacity="0"/>
          <stop offset="0.671875" stop-color="#57AE8E"/>
        </radialGradient>
      </defs>
    </svg>

    <svg class="circle-02" width="584" height="446" viewBox="0 0 584 446" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.75" cx="576" cy="576" r="576" fill="url(#paint0_radial_836_5991)"/>
      <defs>
        <radialGradient id="paint0_radial_836_5991" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(576 576) rotate(90) scale(576)">
          <stop stop-color="#5EADE0" stop-opacity="0.1"/>
          <stop offset="1" stop-color="#5EADE0"/>
        </radialGradient>
      </defs>
    </svg>

    <svg class="circle-03" width="4521" height="2808" viewBox="0 0 4521 2808" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse opacity="0.3" cx="2260.72" cy="1404.16" rx="2330.5" ry="1282" transform="rotate(17.0248 2260.72 1404.16)" fill="url(#paint0_radial_835_5971)"/>
      <defs>
        <radialGradient id="paint0_radial_835_5971" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2260.72 1404.16) rotate(90) scale(1282 2330.5)">
          <stop stop-color="#5EADE0" stop-opacity="0.1"/>
          <stop offset="1" stop-color="#5EADE0"/>
        </radialGradient>
      </defs>
    </svg>
  </div>
</ng-container>
