/// <reference types="@angular/localize" />

import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, withComponentInputBinding, withRouterConfig } from '@angular/router';
import { getAuth, inMemoryPersistence, provideAuth } from '@angular/fire/auth';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { APP_ROUTES } from './app/app.routes';
import { environment } from './environments/environment';
import Rollbar from 'rollbar';
import { VERSION } from './app/version';
import { setPersistence } from 'firebase/auth';
import { provideHttpClient } from '@angular/common/http';
import { TranslocoHttpLoader } from './app/services/transloco-loader';
import { provideTransloco } from '@ngneat/transloco';
import { RollbarService } from './app/services/rollbar.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppErrorService } from './app/app-error/app-error.service';

const rollbarConfig = {
  accessToken: '9638749b1f734c32aa8e22491868ad26',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: environment.production,
  payload: {
    environment: environment.production ? 'production' : 'other',
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: VERSION,
        guess_uncaught_frames: true
      }
    }
  }
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    @Inject(AppErrorService) private appError: AppErrorService
  ) {}

  handleError(err: unknown) : void {
    const maybeMessage = (err as Error).message;
    if (maybeMessage.includes('Loading chunk') || maybeMessage.includes('dynamically imported module') ||
      maybeMessage.includes('chunk-')) {
      this.appError.showError(true);
      return;
    }

    if (environment.production) {
      this.rollbar.error(err as Rollbar.LogArgument);
    }

    throw err;
  }
}


export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimations(),
    provideRouter(APP_ROUTES, withComponentInputBinding(), withRouterConfig({paramsInheritanceStrategy: 'always'})),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      // As httpOnly cookies are to be used, do not persist any state client side.
      // Note setPersistence is imported from 'firebase/auth' https://stackoverflow.com/questions/69292778/angularfire-auth-persistence-cannot-be-invoked-without-new
      const auth = getAuth();
      setPersistence(auth, inMemoryPersistence);
      return auth;
    }),
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: Window, useValue: window },
    provideHttpClient(),
    provideTransloco({
      config: {
        availableLangs: ['en', 'bn'],
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !environment.production,
      },
      loader: TranslocoHttpLoader
    })
  ]
})
  .catch(err => console.error(err));
