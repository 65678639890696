import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitialLoaderService } from './initial-loader.service';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'portal-initial-loader',
    imports: [CommonModule],
    templateUrl: './initial-loader.component.html',
    styleUrls: ['./initial-loader.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: 0 }), animate(300, style({ opacity: 1 }))
            ]),
            transition(':leave', [
                animate(300, style({ opacity: 0 }))
            ])
        ])
    ]
})
export class InitialLoaderComponent {
  constructor(protected loader: InitialLoaderService) {
  }
}
