import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { catchError, filter, finalize, from, map, mergeMap, Observable, of, take, tap } from 'rxjs';
import { ToastComponent } from './toast/toast.component';
import { AppErrorComponent } from './app-error/app-error.component';
import { ApplicationStateService } from './services/application-state.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoService } from '@ngneat/transloco';
import { HeaderComponent } from './header/header.component';
import { AppErrorService } from './app-error/app-error.service';
import { ErrorSilencerService } from './services/error-silencer.service';
import { LoadingOverlayComponent } from './shared/loading-overlay/loading-overlay.component';
import { LoadingOverlayService } from './shared/loading-overlay/loading-overlay.service';
import { InitialLoaderComponent } from './inital-loader/initial-loader.component';
import { InitialLoaderService } from './inital-loader/initial-loader.service';
import { AuthenticationService } from './services/authentication.service';
import { WorkInProgressComponent } from './work-in-progress/work-in-progress.component';
import { TrpcService } from './services/trpc.service';

@Component({
  selector: 'portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, ToastComponent, AppErrorComponent, CommonModule, FontAwesomeModule, HeaderComponent,
    InitialLoaderComponent, LoadingOverlayComponent, WorkInProgressComponent]
})
export class AppComponent implements OnInit {
  title = 'user-portal';

  constructor(
    private authentication: AuthenticationService,
    private router: Router,
    private appState: ApplicationStateService,
    private translocoService: TranslocoService,
    private errorService: AppErrorService,
    private errorSilencer: ErrorSilencerService,
    private loadingOverlay: LoadingOverlayService,
    protected initialLoader: InitialLoaderService,
    private trpc: TrpcService
  ) {
    this.initialLoader.show();
  }

  get isInMaintenance(): boolean {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    return (window as any).MAINTENANCE === true;
  }

  ngOnInit(): void {
    this.monitorRoutesChange();
    this.loadLanguage();
    this.authenticate();
  }

  private monitorRoutesChange(): void {
    this.router.events
      .pipe(
        filter((event): event is NavigationStart => event instanceof NavigationStart),
        tap(event => {
          const match = event.url.match(/^\/(org|providers)\/([^/]+)\//);
          this.appState.setState({ organization: match ? match[2] : undefined });
        })
      )
      .subscribe();
  }

  private authenticate() {
    this.authentication.ssoSignIn()
      .pipe(
        catchError(err => {
          // defer error handling to routes
          // some routes are open
          if (!this.errorSilencer.shouldSilenceUnauthorized(err)) {
            // ignore expected unauthorized error
            this.errorService.showError();
          }
          return of(null);
        }),
        tap(user => this.appState.setState({ user })),
        mergeMap(() => this.loadLanguage()),
        finalize(() => this.initialLoader.hide())
      )
      .subscribe();
  }

  private loadLanguage(): Observable<unknown> {
    return this.appState.subscribe(state => state)
      .pipe(
        filter(state => state.user !== undefined),
        take(1), // Language should be set only once
        // When user === null, user is not authenticated, use organization in URL/path
        map(state => state.user?.organization ?? state.organization!),
        mergeMap(organization => from(this.trpc.client.organization.language.query(organization))),
        tap(language => this.translocoService.setActiveLang(language))
      );
  }
}
