import { Component } from '@angular/core';
import { LoadingOverlayService } from './loading-overlay.service';
import { CommonModule } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'portal-loading-overlay',
    imports: [CommonModule],
    templateUrl: './loading-overlay.component.html',
    styleUrls: ['./loading-overlay.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(300, style({ opacity: 1 }))
            ]),
            transition(':leave', [
                animate(300, style({ opacity: 0 }))
            ])
        ])
    ]
})
export class LoadingOverlayComponent {
  constructor(private overlayService: LoadingOverlayService) {
  }

  get shouldShow(): boolean {
    return this.overlayService.isShown;
  }
}
