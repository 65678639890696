import { Component, HostBinding } from '@angular/core';
import { ToastService } from './toast.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { trackById } from '../utils/track-by';
import { RefreshToastService } from './refresh-toast.service';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'portal-toasts',
    imports: [CommonModule, FontAwesomeModule],
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    animations: [
        trigger('fadeSlideInInOutOut', [
            transition(':enter', [
                style({ transform: 'translateX(100%)', opacity: 0 }),
                animate(300, style({ transform: 'translateX(0%)', opacity: 1 }))
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0%)', opacity: 1 }),
                animate(300, style({ transform: 'translateX(100%)', opacity: 0 }))
            ])
        ])
    ]
})
export class ToastComponent {
  @HostBinding('class') class = 'toast-container position-fixed top-0 end-0 p-3 me-1 me-md-5';
  @HostBinding('style') style = 'z-index: 1200';

  protected readonly faSquare = faSquare;
  protected readonly trackById = trackById;

  constructor(
    public toastService: ToastService,
    public refreshToastService: RefreshToastService
  ) { }

}
