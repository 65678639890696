import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { from, mergeMap, take, tap } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import { Auth, authState, signOut, User } from '@angular/fire/auth';
import { TrpcService } from '../../services/trpc.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserResponseType } from 'portal-types';
import { LoadingOverlayService } from '../../shared/loading-overlay/loading-overlay.service';
import { ApplicationStateService } from '../../services/application-state.service';
import { AuthenticationService } from '../../services/authentication.service';
import { TranslocoDirective } from '@ngneat/transloco';
import { InitialLoaderService } from '../../inital-loader/initial-loader.service';
import { faArrowUpRightFromSquare, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'portal-user',
    imports: [CommonModule, RouterModule, FormsModule, TranslocoDirective, FontAwesomeModule],
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  protected readonly faUser = faUser;
  protected readonly faExternalNav = faArrowUpRightFromSquare;

  fUser: User | undefined;
  user: UserResponseType | undefined;
  organization: string | undefined;

  protected allowAbfasPortalNav: boolean = false;

  private destroyRef = inject(DestroyRef);

  constructor(private fbAuth: Auth,
              private authentication: AuthenticationService,
              private trpc: TrpcService,
              private overlay: LoadingOverlayService,
              private router: Router,
              private initialLoader: InitialLoaderService,
              protected appState: ApplicationStateService) {
  }

  ngOnInit(): void {
    authState(this.fbAuth)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(user => this.fUser = user ?? undefined)
      )
      .subscribe()


    this.appState.subscribe(state => state)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(state => {
          this.user = state.user ?? undefined;
          this.organization = state.organization;
          this.allowAbfasPortalNav = state.organization === 'abfas' && environment.production;
        })
      )
      .subscribe();
  }

  selectOrganization(organization: string): void {
    if (!this.user || this.user!.organization === organization) {
      return;
    }

    this.overlay.show();
    from(this.trpc.client.users.switch.mutate(organization))
      .pipe(
        tap(() => window.location.reload())
      )
      .subscribe();
  }

  showUserProfile() {
    this.appState.setState({ custom: { showProfile: true } });
  }

  logout() {
    if (this.allowAbfasPortalNav) {
      window.open('https://my.abfas.org', '_blank');
    } else {
      this.overlay.show();
      from(this.authentication.ssoSignOut())
        .pipe(
          mergeMap(() => signOut(this.fbAuth))
        )
        .subscribe();
      }
  }

  getLogoutTriggerName(): string {
    return this.allowAbfasPortalNav
      ? 'DPM Portal'
      : 'Sign out'
  }

  copyIdToken(): void {
    from(this.authentication.getToken())
      .pipe(
        tap(token => navigator.clipboard.writeText(token!)),
        take(1)
      )
      .subscribe();
  }
}
